/* istanbul ignore file */
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Keyboard, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';

import { FillAbsoluteCenter, ScrollableContainer } from 'components/Containers';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { useTransformContentStyleVertical } from 'components/ResponsiveLayout/hooks/useGetDynamicContentStyles';
import { useSafeParams } from 'components/Routes/useSafeParams';
import MFAVerification from 'components/Screens/LoginScreen/components/MFAVerification/MFAVerification';
import {
  INVALID_EMAIL,
  MISSING_EMAIL,
  MISSING_PASSWORD,
} from 'components/Screens/LoginScreen/constants';
import { getErrorString } from 'components/Screens/LoginScreen/utils/getErrorString';
import { Text } from 'components/Text';
import {
  ButtonColorSchemes,
  ButtonSizes,
  UniversalButton,
} from 'components/UniveralButtons';
import { Redirect, useHistory } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';
import { MultiLineInput } from 'daylight/components/inputs';
import { useTransformContentStyle } from 'daylight/hooks';
import { LoginPhases, useGeneralLogin } from 'lib/api/hooks/useLogin';
import * as reporter from 'lib/reporter';

import { CloseIcon } from '../../icons/CloseIcon';

import {
  AttemptErrorContainer,
  AttemptValidationText,
  ButtonContainer,
  ErrorContainer,
  LimitWidth,
  PaddingContainer,
  ValidationText,
} from './LoginScreen.styled';

const PaddedContainer = styled(FillAbsoluteCenter)`
  background-color: white;
`;

export const LoginScreen = (): ReactElement => {
  const [showMFAVerification, setShowMFAVerification] = useState(false);
  const [otpError, setOtpError] = useState<undefined | Error>(undefined);
  const { productReference } = useSafeParams();
  const transformStyle = useTransformContentStyle();
  const transformStyleVertical = useTransformContentStyleVertical();
  const [loginError, setLoginError] = useState<undefined | Error>(undefined);
  const [isEmailFocused, setIsEmailFocused] = useState<boolean>(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState<boolean>(false);

  // TODO: uncomment this line
  // WHEN: Daylight Legacy system is deleted and we don't have to check Legacy users anymore
  // const { login, error, status } = useBigHealthLogin();

  // TODO: delete this line
  // WHEN Daylight Legacy system is deleted and we don't have to check Legacy users anymore
  const { login, error, status } = useGeneralLogin();

  const theme = useContext(ThemeContext);

  const history = useHistory();
  const [email, setEmail] = useState({
    isValid: false,
    value: '',
    isPresent: false,
    isSubmitted: false,
  });
  const [password, setPassword] = useState({
    isValid: false,
    isSubmitted: false,
    value: '',
  });
  const canSignIn = email.isValid && password.isValid;

  const hasEmailPresentError = email.isSubmitted && !email.isPresent;
  const hasEmailValidError =
    email.isSubmitted && !email.isValid && email.isPresent;
  const hasPasswordError = password.isSubmitted && !password.isValid;

  useEffect(() => {
    if (error) {
      reporter.log(error.message, error, { silent: true });
      setLoginError(error);
    }
  }, [error, status]);

  const handleSubmit = async (): Promise<void> => {
    setEmail(prevState => ({ ...prevState, isSubmitted: true }));
    setPassword(prevState => ({ ...prevState, isSubmitted: true }));
    setLoginError(undefined);
    setOtpError(undefined);

    setIsPasswordFocused(false);
    setIsEmailFocused(false);
    Keyboard.dismiss();

    if (canSignIn) {
      try {
        await login({ email: email.value, password: password.value });
      } catch (e) {
        reporter.log(e.message, e);
      }
    }
  };

  // Logic created to clear the input text when there was an error and the user tabs on the password input.
  useEffect(() => {
    if (error && isPasswordFocused) {
      setPassword(prevState => ({ ...prevState, value: '' }));
    }
  }, [error, isPasswordFocused]);

  const onChangeEmail = (value: string): void => {
    setEmail(prevState => ({
      ...prevState,
      value: value,
      isValid: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value),
      isPresent: value.length > 0,
      isSubmitted: false,
    }));
  };

  const onChangePassword = (value: string): void => {
    setPassword(prevState => ({
      ...prevState,
      value: value,
      isValid: value.length > 0,
      isSubmitted: false,
    }));
  };

  const onOtpErrorHandler = (message: string | undefined) => {
    setShowMFAVerification(false);
    setPassword(prevState => ({ ...prevState, value: '' }));
    setOtpError(new Error(message));
  };

  useEffect(() => {
    if (status === LoginPhases.OTP) {
      setShowMFAVerification(true);
    }
  }, [status]);

  switch (status) {
    case LoginPhases.SUCCESS:
      // NOTE: the login call returns the refresh token in the Response Headers.
      // We redirect to home and then we fetch the Program, we get Tokens not Found and we
      // refresh the tokens using the refresh token in the headers.
      // Once we Daylight Legacy system is deleted and we switch to the other hook
      // we won't have this "refresh" extra call anymore.
      return <Redirect to={`/${productReference}/home`} />;
    default:
      break;
  }

  const handleXIcon = async (): Promise<void> => {
    history.push(`/${productReference}/login`);
  };

  return (
    <PaddedContainer>
      <LimitWidth>
        <View
          style={{
            position: 'absolute',
            right: transformStyle(20),
            top: transformStyleVertical(60),
          }}
        >
          <CloseIcon
            size={transformStyle(22)}
            color={theme.color.text.placeholder || theme.color.primaryGrey}
            onPress={handleXIcon}
          />
        </View>
        <ScrollableContainer keyboardShouldPersistTaps={'handled'}>
          {showMFAVerification ? (
            <MFAVerification
              productReference={productReference}
              email={email.value}
              onError={onOtpErrorHandler}
            />
          ) : (
            <>
              <PaddingContainer>
                <Text
                  {...roles('LoginInput-email-label')}
                  style={{ marginBottom: 8, color: theme.color.text.primary }}
                >
                  Email address
                </Text>
                <MultiLineInput
                  {...roles('LoginInput-email')}
                  onChangeText={onChangeEmail}
                  onFocus={() => {
                    setIsEmailFocused(true);
                    setIsPasswordFocused(false);
                  }}
                  placeholder={'Enter your email'}
                  placeholderTextColor={theme.color.text.placeholder}
                  keyboardType={'email-address'}
                  type={'email'}
                  autoFocus={false}
                  hasErrors={Boolean(
                    hasEmailPresentError || hasEmailValidError || error
                  )}
                  isFocused={isEmailFocused}
                  required={true}
                  autoCapitalize={'none'}
                  returnKeyType={'next'}
                  autoCorrect={false}
                  value={email.value}
                />
                {hasEmailPresentError ? (
                  <ErrorContainer>
                    <ValidationText>{MISSING_EMAIL}</ValidationText>
                  </ErrorContainer>
                ) : null}
                {hasEmailValidError ? (
                  <ErrorContainer>
                    <ValidationText>{INVALID_EMAIL}</ValidationText>
                  </ErrorContainer>
                ) : null}
              </PaddingContainer>
              <PaddingContainer>
                <Text
                  {...roles('LoginInput-password-label')}
                  style={{ marginBottom: 8, color: theme.color.text.primary }}
                >
                  Password
                </Text>
                <MultiLineInput
                  {...roles('LoginInput-password')}
                  onChangeText={onChangePassword}
                  onFocus={() => {
                    setIsPasswordFocused(true);
                    setIsEmailFocused(false);
                  }}
                  placeholder={'Enter your password'}
                  placeholderTextColor={theme.color.text.placeholder}
                  required={true}
                  value={password.value}
                  secureTextEntry={true}
                  hasErrors={Boolean(hasPasswordError || error)}
                  isFocused={isPasswordFocused}
                />
                {hasPasswordError ? (
                  <ErrorContainer>
                    <ValidationText>{MISSING_PASSWORD}</ValidationText>
                  </ErrorContainer>
                ) : null}
                {loginError && email.isSubmitted && password.isSubmitted ? (
                  <AttemptErrorContainer>
                    <AttemptValidationText>
                      {getErrorString(loginError)}
                    </AttemptValidationText>
                  </AttemptErrorContainer>
                ) : null}
                {otpError ? (
                  <PaddingContainer>
                    <ErrorContainer>
                      <ValidationText>
                        {getErrorString(otpError)}
                      </ValidationText>
                    </ErrorContainer>
                  </PaddingContainer>
                ) : null}
              </PaddingContainer>
              <ButtonContainer
                style={{ paddingTop: transformStyleVertical(52) }}
              >
                <UniversalButton
                  {...roles('Login-button')}
                  isDisabled={!(password.value && email.value)}
                  onPress={handleSubmit}
                  text={'Continue'}
                  colorScheme={ButtonColorSchemes.Primary}
                  size={ButtonSizes.Small}
                />
                <Text
                  testID="forgot-password-link"
                  style={{ padding: transformStyle(16) }}
                  onPress={() =>
                    history.push(`/${productReference}/forgot-password`)
                  }
                >
                  Forgot your password?
                </Text>
              </ButtonContainer>
            </>
          )}
        </ScrollableContainer>
      </LimitWidth>
      {status === LoginPhases.FETCHING ? <LoadingOverlay /> : null}
    </PaddedContainer>
  );
};

export default LoginScreen;
