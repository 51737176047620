/* eslint-disable camelcase */
// IDEA package up selection state into hook
// WHEN ever decide to reuse OR this file gets to big
// HOW onSelect, onSelectValue, onChange, useState into useResponseOptionField
import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Question, ResponseOption, TextResponseOption } from '@bighealth/types';
import { ResponseInputComponentType } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';

import { InputFocusProvider } from 'components/forms/ResponseOptions/providers/InputFocusProvider';
import { Value } from 'components/forms/types';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { InputsAsArray } from 'components/generic-question/InputsAsArray';
import { roles } from 'cross-platform/utils/roleProps';
import { useLatestFocusedQuestion } from 'lib/question-response/useLatestFocusedQuestion';
import { useLatestQuestion } from 'lib/question-response/useLatestQuestion';
import { useQuestionPathState } from 'lib/question-response/useQuestionPathState';
import { QuestionPathProperties } from 'state/question-path/actions';

import { useResponseOptionField } from '../hooks/useField';

import { addItem, setSelection, toggleItem } from './utils/queue';
import { ResponseInputState } from './types';

export type ResponseInputProps = PropsWithChildren<{
  questionProps: Question;
  component: ResponseInputComponentType;
  warning?: string;
  highlight?: boolean;
  initialValue?: Value;
  state?: ResponseInputState;
  schedule_delay_in_minutes?: number;
  submitOnSelect?: (value: Record<string, DropdownItem[]>) => void;
  disabled?: boolean;
}>;

const ResponseInput = (props: ResponseInputProps): ReactElement => {
  const {
    field,
    meta,
    helpers,
    quizForQuestion,
    initialValue,
  } = useResponseOptionField(
    props.questionProps,
    props.component,
    props.initialValue
  );
  if (typeof props.questionProps.id !== 'number') {
    throw TypeError(
      `Expected questionId to be number: ${props.questionProps.id}`
    );
  }
  const [touchedOptionsIds, setTouchedOptionsIds] = useState<number[]>([]);
  const { setLatestFocusedQuestion } = useLatestFocusedQuestion();
  const { setLatestQuestionId } = useLatestQuestion();

  useEffect(() => {
    setLatestQuestionId(props.questionProps.id);
  }, [props.questionProps.id, setLatestQuestionId]);

  const [selectedQueue, setSelectedQueue] = useQuestionPathState(
    props.questionProps.id,
    QuestionPathProperties.selectionQueue,
    []
  );

  // IDEA Don't check if quizCompleted via attemptsLeft, check using correctSelection
  // WHY by creating new variable, allows data to get out of sync
  // HOW ensure quizForQuestion.assessment.correctSelection updates when
  // tryForceComplete() calls submitQuizAnswers()
  const isQuizCompletedComplete =
    quizForQuestion.assessment?.attemptsLeft === 0;

  /**
   * Handles the selection in case of a Question with multiple options.
   *
   * @param selected the Option selected by the user
   * @returns
   */
  const onSelect = (selected?: ResponseOption): void => {
    if (isQuizCompletedComplete || props.disabled) {
      return;
    }

    const toggledItems = manageItemsToggle(selected);
    setSelectedQueue(toggledItems.selectedQueue);

    helpers.setTouched(true);
    helpers.setValue(toggledItems.all);
    setLatestFocusedQuestion(props.questionProps.id);

    if (props.submitOnSelect && toggledItems.selectedQueue.length > 0) {
      props.submitOnSelect({ [field.name]: toggledItems.all });
    }
  };

  /**
   * Handles the selection in case of a Dropdwon question
   *
   * @param selectedValue the value in the dropdown selected by the user
   * @returns
   */
  const onSelectSingleValue = (selectedValue: string | Date | null): void => {
    let maybeCastValue: typeof selectedValue | number = selectedValue;
    if (
      props.questionProps.response_type === 'number' &&
      // We're always cool with empty string
      selectedValue !== ''
    ) {
      maybeCastValue = Number(selectedValue);
    }

    if (isQuizCompletedComplete || props.disabled) {
      return;
    }
    // INFO Only for Dropdown (Html select / Native picker)
    const selectedOption = props.questionProps.response_config.response_options.find(
      option => option.value === maybeCastValue
    );
    const foundItem: DropdownItem | undefined = field.value.find(
      item => item?.id === selectedOption?.id
    );
    helpers.setTouched(true);
    helpers.setValue(
      setSelection(
        props.questionProps.response_config.max_selections_required,
        field.value,
        foundItem ? [foundItem] : undefined
      )
    );
    setLatestFocusedQuestion(props.questionProps.id);
  };

  /**
   * Set the value entered by the user in case of a free-text Input Option
   *
   * @param option the option selected by the user
   * @param value the value entered by the user in the selected option
   * @returns
   */
  const onSetCustomValue = (
    option: ResponseOption,
    value = initialValue[0]?.value
  ): void => {
    if (isQuizCompletedComplete || props.disabled) {
      return;
    }
    const itemsWithValue = field.value.map(
      (item: DropdownItem): DropdownItem =>
        item.id === option.id ? { ...item, value } : item
    );
    const helperValue = addItem(
      props.questionProps.response_config.max_selections_required,
      itemsWithValue,
      itemsWithValue.find(item => item.id === option?.id)
    );

    helpers.setTouched(true);
    if (!selectedQueue.some(item => item.id === option.id)) {
      const toggledItems = manageItemsToggle(option, helperValue);
      setSelectedQueue(toggledItems.selectedQueue);
      helpers.setValue(toggledItems.all);
    } else {
      helpers.setValue(helperValue);
    }
    setTouchedOptionsIds(state => [...state, option.id]);
    setLatestFocusedQuestion(props.questionProps.id);
  };

  const manageItemsToggle = (
    selected?: ResponseOption,
    allItems: DropdownItem[] = field.value
  ): { all: DropdownItem[]; selectedQueue: DropdownItem[] } => {
    const intendedSelection = allItems.filter(
      e => e.isSelected || e.id === selected?.id
    );

    // The IDs for response options that, when clicked, should be the only
    // option selected. These act like "radio" inputs.
    const singleSelectIds = props.questionProps.response_config.response_options
      .filter(responseOption => responseOption?.selection_behavior === 'radio')
      .map(responseOption => `${responseOption.id}`);

    const toggledItems = toggleItem(
      props.questionProps.response_config.max_selections_required,
      singleSelectIds,
      allItems,
      selectedQueue,
      allItems.find(item => item.id === selected?.id),
      intendedSelection.filter(e =>
        quizForQuestion.assessment?.correctSelection.includes(e.id as number)
      ),
      intendedSelection.filter(e =>
        quizForQuestion.assessment?.incorrectSelection.includes(e.id as number)
      )
    );

    return toggledItems;
  };

  const questionProps = useMemo(() => {
    /*
     * If the question has been answered and the user has selected a different item or submit the input(question option),
     * we need to check if the user has entered a valid value (min_characters_limit...).
     * If so, we need to display an error message on the specific option.
     */
    let extra_response_options = [
      ...props.questionProps.response_config.response_options,
    ];
    const haveQuestionMetaDataRelated =
      meta?.value && Array.isArray(meta?.value) && meta?.value.length > 0;
    if (haveQuestionMetaDataRelated) {
      // Add extra information to question options in order to display error messages
      extra_response_options = extra_response_options.map(
        (option: ResponseOption) => {
          let optionError = undefined;
          const selectedOptionValue = meta.value.find(
            itemValue => itemValue.id === option.id
          );
          const characterLimit = (option as TextResponseOption)
            .min_characters_limit;

          const hasOptionValueCorrectFormat =
            selectedOptionValue && // Validate if the option have related information on meta content
            typeof selectedOptionValue.value === 'string' &&
            typeof characterLimit === 'number' &&
            selectedOptionValue.value?.length >= characterLimit;

          const wasCurrentOptionTouched =
            meta.touched && touchedOptionsIds.includes(option.id); // Check if current the option was touched

          const shouldDisplayIndependentOptionError =
            !hasOptionValueCorrectFormat && wasCurrentOptionTouched;

          if (shouldDisplayIndependentOptionError) {
            optionError = meta.error;
          }
          return {
            ...option,
            error: optionError,
          };
        }
      );
      return {
        ...props.questionProps,
        response_config: {
          ...props.questionProps.response_config,
          response_options: extra_response_options,
        },
      };
    }
    return props.questionProps;
  }, [
    props.questionProps,
    meta.value,
    meta.touched,
    meta.error,
    touchedOptionsIds,
  ]);

  const questionError = useMemo(() => {
    /*
      Depending on the question type and content, we will use the general question error
      or the specific option error.
    */
    if (
      questionProps.response_config.response_options.some(
        (option: ResponseOption & { error?: string | boolean }) => option.error
      )
    ) {
      return true;
    }
    return (
      meta.error ||
      (quizForQuestion?.assessment?.isAllCorrect === true
        ? undefined
        : typeof quizForQuestion?.assessment?.attemptsLeft !== 'undefined'
        ? `You have ${quizForQuestion?.assessment?.attemptsLeft} attempts left`
        : undefined)
    );
  }, [
    meta.error,
    quizForQuestion.assessment,
    questionProps.response_config.response_options,
  ]);

  return (
    <InputFocusProvider questionId={props.questionProps.id}>
      <InputsAsArray
        {...roles(`ResponseInput:${props.questionProps.semantic_id}`)}
        state={props.state}
        onSelect={onSelect}
        onSelectValue={onSelectSingleValue}
        onSetValue={onSetCustomValue}
        onBlur={(): void => {
          helpers.setTouched(true);
        }}
        questionProps={questionProps}
        component={props.component}
        selection={field.value || initialValue}
        isQuizCompletedComplete={isQuizCompletedComplete}
        correctSelection={quizForQuestion.assessment?.correctSelection}
        incorrectSelection={quizForQuestion.assessment?.incorrectSelection}
        attempts={quizForQuestion?.attempts}
        error={questionError}
        warning={props.warning}
        highlight={props.highlight}
        isTouched={meta.touched}
        schedule_delay_in_minutes={props.schedule_delay_in_minutes}
      />
    </InputFocusProvider>
  );
};

export { ResponseInput };
