import React, { ReactElement } from 'react';

import { ScheduleContentListProps, ScheduleScreen } from '@bighealth/types';

import { useParams } from 'cross-platform/react-router';

import {
  Content,
  ContentSubtitle,
  ContentTitle,
  ContentTitleBlock,
  SkeletonOuterContainerAnimated,
} from '../components';
import { isNonEmptyPageData } from '../components/helpers';

import { ScheduleContentEntry } from './ScheduleContentEntry';
import { ScheduleScreenParams } from './types';

export const ScheduleContent = ({
  content,
  isLoading,
}: {
  isLoading?: boolean;
  content?: ScheduleScreen;
}): ReactElement | null => {
  const { page } = useParams<ScheduleScreenParams>();
  if (isLoading) {
    return <SkeletonOuterContainerAnimated />;
  }
  if (typeof content === 'undefined') {
    return null;
  }
  if (!isNonEmptyPageData(content)) {
    return null;
  }
  const selectedItem = content.find(({ slug }) => slug === page);

  if (typeof selectedItem === 'undefined') {
    return null;
  }

  return (
    <Content>
      <ContentTitleBlock>
        <ContentTitle>{selectedItem?.title}</ContentTitle>
        <ContentSubtitle>{selectedItem?.subtitle}</ContentSubtitle>
      </ContentTitleBlock>
      {(selectedItem?.content as ScheduleContentListProps[]).map(
        (props, index) => (
          <ScheduleContentEntry {...props} key={index} />
        )
      )}
    </Content>
  );
};
