import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSafeArea } from 'react-native-safe-area-context';

import { APIRequestBody } from '@bighealth/api';
import { ClientFeatureVersions } from '@bighealth/types';
import { SubmitDiaryResult } from '@bighealth/types/dist/services/SleepDiaryPayloads/submit_single_diary_form';
import { SleepDiaryFormProps } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';
import { SleepDiaryPayloads } from '@bighealth/types/src/services/SleepDiaryPayloads';

import { ProductReferences } from 'common/constants/enums';
import { OnValidateCallback } from 'components/forms';
import { useModalControlsStateContext } from 'components/PopupModal/components/Presentational/components/ModalControlsContext';
import { CrossPlatformThemeProvider } from 'components/ProvidersContainer/ProductThemeProvider';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import { ErrorText } from 'components/Text/ErrorText';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { roles } from 'cross-platform/utils/roleProps';
// TODO: move the hook outside of the SessionScreen to make it avaialable in the whole codebase
import { apiAndThrow } from 'lib/api/helpers/apiAndThrow';
import { useQueryProgram } from 'lib/api/reactQueryHelpers';

import { DateLabel, Heading } from '../../styled';
import { FlowingForm } from '../FlowingForm';
import { Reinforcement } from '../Reinforcement';

import { fetchRenderDataAsync } from './helpers/fetchRenderDataAsync';
import { isSubmitSingleDiaryFormResult } from './helpers/guards/isSubmitSingleDiaryFormResult';
import {
  Args as UseSubmitHandlerArgs,
  useSubmitHandler,
} from './helpers/useSubmitHandler';
import {
  SubmitCallbackProvider,
  SubmitState,
} from './providers/SubmitCallbackProvider';
import { ErrorButton, ErrorWrapper, LoadingWrapper, Wrapper } from './styled';

type FetchData = Parameters<typeof fetchRenderDataAsync>[0]['fetchData'];

export type NetworkedFlowingFormProps = {
  renderRequest: APIRequestBody;
  initialPayload?: SleepDiaryFormProps; // As of Sep5 2020, only for storybook
  onValidate?: OnValidateCallback;
  onClose?: () => void;
  onSuccessfulSubmit?: (timestamp: number) => void;
};
/**
 * NetworkedFlowingForm
 * onLoad: call API to get form data
 * onSubmit: call API to save responses
 *
 * @param renderRequest
 * @param props.initialPayload for testing
 */
const NetworkedFlowingForm = (
  props: NetworkedFlowingFormProps
): ReactElement | null => {
  const [payload, setPayload] = useState<SleepDiaryFormProps | undefined>(
    props?.initialPayload
  );
  const { sceneSetId, sceneId } = useSafeParams<SceneSetParams>();
  const [isLoading, setIsLoading] = useState<boolean>(
    !!props?.initialPayload || true
  );
  const [reinforcementData, setReinforcementData] = useState<
    SubmitDiaryResult | undefined
  >(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [submitState, setSubmitState] = useState<SubmitState>({});
  const { setCustomOnClose } = useModalControlsStateContext();

  const programId = useQueryProgram()?.data?.result.id;

  const renderRequest = props.renderRequest as SleepDiaryPayloads['get_diary_entry_form']['request'];
  renderRequest.args.sleep_diary_form_version = 3 as ClientFeatureVersions.SleepDiaryFormVersion;
  const fetchRenderData = (React.useCallback(() => apiAndThrow(renderRequest), [
    renderRequest,
  ]) as unknown) as FetchData;

  const fetchRenderDataCallback = React.useCallback(() => {
    fetchRenderDataAsync({
      fetchData: fetchRenderData,
      onLoadingChange: setIsLoading,
      onSuccess: setPayload,
      onError: setError,
    });
  }, [fetchRenderData]);

  useEffect(() => {
    if (typeof programId !== 'undefined') {
      fetchRenderDataCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  const handleSuccess = useCallback(() => {
    if (typeof props.onSuccessfulSubmit === 'function') {
      props.onSuccessfulSubmit(Date.now());
    }
    if (typeof submitState?.onSubmitDone === 'function') {
      submitState?.onSubmitDone();
    }
  }, [props, submitState]);

  const onSuccess: UseSubmitHandlerArgs['onSuccess'] = useCallback(
    payload => {
      if (isSubmitSingleDiaryFormResult(payload)) {
        setReinforcementData(payload.result);

        if (typeof setCustomOnClose === 'function') {
          setCustomOnClose(() => handleSuccess);
        }
      } else {
        handleSuccess();
      }
    },
    [handleSuccess, setCustomOnClose]
  );

  const submitHandler = useSubmitHandler({
    renderRequest,
    onSuccess,
    programId,
    setError,
    setIsSubmitting,
    sceneSetId,
    sceneId,
  });
  const safeArea = useSafeArea();

  return (
    <CrossPlatformThemeProvider
      theme={getThemeForProduct(ProductReferences.SLEEPIO, 'v2')}
    >
      <Wrapper>
        {isLoading ? (
          <LoadingWrapper
            {...roles('NetworkedFlowingForm.Loading')}
            style={{ paddingLeft: safeArea.right }}
          >
            <DateLabel />
            <Heading text="Sleep Diary is loading" />
          </LoadingWrapper>
        ) : error ? (
          <ErrorWrapper {...roles('NetworkedFlowingForm.Error')}>
            <DateLabel />
            <Heading text="Sleep Diary could not load" />
            <ErrorText>{`${error}`}</ErrorText>
            <ErrorButton
              {...roles('NetworkedFlowingForm.TryAgain')}
              text="Try again"
              onPress={fetchRenderDataCallback}
            />
          </ErrorWrapper>
        ) : payload ? (
          typeof reinforcementData === 'undefined' ? (
            <SubmitCallbackProvider
              value={{ setSubmitState, submitState, isSubmitting }}
            >
              <FlowingForm
                {...payload}
                {...roles('NetworkedFlowingForm.Rendered')}
                diaryDateStr={renderRequest.args.diary_date?.$date}
                onSubmit={submitHandler}
                onClose={props.onClose}
                onValidate={props?.onValidate}
                isNewForm={
                  renderRequest.args.form_type ===
                    ('submit_single_diary_form' as unknown) || false
                }
              />
            </SubmitCallbackProvider>
          ) : (
            <Reinforcement
              {...reinforcementData}
              date_label={payload?.date_label}
              onClose={handleSuccess}
            />
          )
        ) : null // Note: Should never get here
        }
      </Wrapper>
    </CrossPlatformThemeProvider>
  );
};

export { NetworkedFlowingForm };
