import React, { ReactElement } from 'react';
import { Linking } from 'react-native';
import { View } from 'react-native';

import { DEFAULT_SUPPORT_EMAIL } from 'daylight/constants';
import { useTransformContentStyle } from 'lib/hooks/useTransformContentStyle';

import { SettingsScreen } from '../SettingsScreen';
import {
  SettingsContentLinkText,
  SettingsContentText,
} from '../SettingsScreen.styled';

export const ContactUsView = (): ReactElement => {
  const transformStyle = useTransformContentStyle();
  return (
    <SettingsScreen title={'Contact Us'}>
      <View style={{ padding: transformStyle(24) }}>
        <SettingsContentText
          bold
          fontSize={transformStyle(18)}
          marginBottom={transformStyle(10)}
          lineHeight={transformStyle(24.3)}
        >
          Technical Support and Feedback
        </SettingsContentText>
        <SettingsContentText
          fontSize={transformStyle(18)}
          marginBottom={transformStyle(0)}
          lineHeight={transformStyle(24.3)}
        >
          If you are experiencing issues with the app or would like to provide
          feedback, please contact us at
        </SettingsContentText>
        <SettingsContentLinkText
          testID={'Mail-link'}
          fontSize={transformStyle(18)}
          lineHeight={transformStyle(24.3)}
          onPress={() => Linking.openURL(`mailto:${DEFAULT_SUPPORT_EMAIL}`)}
        >
          {DEFAULT_SUPPORT_EMAIL}
        </SettingsContentLinkText>
      </View>
    </SettingsScreen>
  );
};
