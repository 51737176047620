import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { jump_to_specific_scene_set } from '@bighealth/api/SceneSetGraph/v1';
import { SceneSet } from '@bighealth/types';
import {
  JumpToSceneSetSources,
  SceneActionTypes,
} from '@bighealth/types/dist/enums';
import { SceneAction } from '@bighealth/types/src/scene-components/client';

import { ProductReferences } from 'common/constants/enums';
import { QUIZ_FAILED_THROWABLE } from 'components/forms/ResponseOptions/ResponseForm';
import { useNotificationUserSettings } from 'components/LocalNotifications/localNotificationsState';
import { useContentRedirectContext } from 'components/ProvidersContainer/ContentRedirectProvider';
import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import { PreviousHistoryState } from 'components/Routes/types';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import { DAYLIGHT_DAILY__NOTIFICATION_GROUP_CONFIG_ID } from 'config/localNotifications/daylightConfig';
import { SLEEPIO_DAILY__NOTIFICATION_GROUP_CONFIG_ID } from 'config/localNotifications/sleepioConfig';
import { useHistory } from 'cross-platform/react-router';
import { useGoTo } from 'lib/api/hooks/useGoTo';
import {
  useLazyQueryJumpToSceneSetWithAssets,
  useQueryProduct,
  useQueryProgram,
} from 'lib/api/reactQueryHelpers';
import { updateUserMessagingProfile } from 'lib/messaging/messagingUtils';
import getPathForParams from 'lib/player/getPathForParams';
import { getNextPath } from 'lib/player/sceneSetHelpers/getNextPath';
import { useNavigateToJumpedSceneSet } from 'lib/player/useNavigateToJumpedSceneSet';
import * as reporter from 'lib/reporter';
import { stringify } from 'lib/stringify';
import { getQuestionResponseToSubmit } from 'state/question-response/selectors';

import { useNavigateToNextContent } from '../useNavigateToNextContent';

import { openLink } from './helpers/openLink';
import { isOpenLinkAction } from './utils/actionGuards';
import { createServiceCaller } from './utils/createServiceCaller';
import { doEffectServiceCalls } from './utils/doEffectServiceCalls';
import { redirectToNotificationsWhenReturnHome } from './utils/redirectToNotificationsWhenReturnHome';
import { ReminderOptions } from './utils/reminderOptions';
import {
  EffectServiceCalls,
  withEffectFactory,
} from './utils/withEffectFactory';
import { useSubmitOnActionHandler } from './useSubmitOnActionHandler';

export type ActionHandlerCallback = Promise<void> | (() => void) | undefined;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ActionObject = Record<string, any>; // @ TODO don't do this

const useActionHandler = (
  action?: ActionObject
): ActionHandlerCallback | undefined => {
  const {
    productReference,
    sceneSetGraphId,
    sceneSetId,
    sceneId,
  } = useSafeParams<SceneSetParams>();
  const dispatch = useDispatch();
  const history = useHistory<PreviousHistoryState>();
  const productGroupConfigId =
    productReference === ProductReferences.SLEEPIO
      ? SLEEPIO_DAILY__NOTIFICATION_GROUP_CONFIG_ID
      : DAYLIGHT_DAILY__NOTIFICATION_GROUP_CONFIG_ID;
  const userSettings = useNotificationUserSettings(productGroupConfigId);
  const navigateToSceneSet = useNavigateToJumpedSceneSet(action as SceneAction);
  const navigateToNextContent = useNavigateToNextContent();
  const submitResponses = useSubmitOnActionHandler();
  const responseToSubmit = useSelector(getQuestionResponseToSubmit);
  const storedResponse = useRef(responseToSubmit);
  const programId = useQueryProgram()?.data?.result.id;
  const productId = useQueryProduct()?.data?.result.id;
  const goTo = useGoTo();
  const queryClient = getQueryClient();

  const currentPath = history.location.pathname;
  const jumpToSceneSetWithAssets = useLazyQueryJumpToSceneSetWithAssets();
  const currentSceneSet = queryClient.getQueryData<
    jump_to_specific_scene_set.Response
  >(['SceneSet', sceneSetId])?.result?.scene_set_json;

  const { exitContent } = useContentRedirectContext();
  const serviceCaller = createServiceCaller({
    productId: productId as number,
    programId: programId as number,
  });

  // Prevent issue with closures
  useEffect(() => {
    storedResponse.current = responseToSubmit;
  }, [responseToSubmit]);

  // --------------------------------------------------------------------------------
  //
  // Handle NEXT action
  //
  // This simply moves to the next Scene. It should almost certainly ONLY be used
  // for things like Audio/Video actions; if there's Questions on the screen the
  // SUBMIT action must be used instead
  //

  const handleNextAction = useCallback(() => {
    if (currentSceneSet) {
      const nextPath = getNextPath(currentPath);
      history.push(nextPath, {
        pathname: history.location.pathname,
      });
    }
  }, [currentPath, currentSceneSet, history]);

  // --------------------------------------------------------------------------------
  //
  // Handle GO_TO_HOME action
  //
  // This simply sends the user directly to home after submitting responses
  //

  const handleGoHomeAction = useCallback(async () => {
    try {
      await submitResponses(); // can throw QUIZ_FAILED_THROWABLE
      exitContent({ fallback: 'home', productReference });
    } catch (err) {
      // only QUIZ_FAILED_THROWABLE errors are intended for subsequent control flow
      if (err !== QUIZ_FAILED_THROWABLE) {
        throw err;
      }
    }
  }, [exitContent, productReference, submitResponses]);

  // --------------------------------------------------------------------------------
  //
  // Handle GO_TO action
  //
  // Nav user to destination url
  //

  const handleGoToAction = useCallback(async (): Promise<void> => {
    if (!action) {
      return undefined;
    }
    try {
      await submitResponses(); // can throw QUIZ_FAILED_THROWABLE
      await goTo(action?.payload);
    } catch (err) {
      // only QUIZ_FAILED_THROWABLE errors are intended for subsequent control flow
      if (err !== QUIZ_FAILED_THROWABLE) {
        throw err;
      }
    }
  }, [action, goTo, submitResponses]);

  // --------------------------------------------------------------------------------
  //
  // Handle REPEAT_CURRENT_SCENESET action
  //
  // Sends the user back to the start of the SceneSet
  //

  const handleRepeatAction = useCallback(async () => {
    // @ TODO bust cache to force sceneset to re-initialize
    const repeatedSceneSetResult = await jumpToSceneSetWithAssets({
      current_graph_id: sceneSetGraphId,
      current_product_id: productId as number,
      current_program_id: programId as number,
      current_scene_set_id: sceneSetId,
      destination_scene_set_id: sceneSetId,
      source: JumpToSceneSetSources.Content,
    });
    const repeatedSceneSet = repeatedSceneSetResult?.result;
    if (repeatedSceneSet) {
      navigateToSceneSet({
        currentSceneSet: currentSceneSet as SceneSet,
        targetSceneSetId: currentSceneSet?.id as number,
      });
    }
  }, [
    currentSceneSet,
    jumpToSceneSetWithAssets,
    navigateToSceneSet,
    productId,
    programId,
    sceneSetGraphId,
    sceneSetId,
  ]);

  // --------------------------------------------------------------------------------
  //
  // Handle JUMP_TO_SCENESET action
  //
  // This does the following:
  // 1. Submits any responses
  // 2. Fetches SceneSet by sceneSetId AND sceneSetGraphId
  // 3. Attempts to jump to

  const handleJumpToSceneSetAction = useCallback(async (): Promise<void> => {
    try {
      let jumpedSceneSet;
      // Wraps submitResponses and jumpToSceneSetWithAssets inside fetchQuery to avoid double loading at different times of sleepNow and other views. when: poor internet connection
      await queryClient.fetchQuery(['navigation-transaction'], async () => {
        await submitResponses(); // can throw QUIZ_FAILED_THROWABLE
        const jumpedSceneSetResult = await jumpToSceneSetWithAssets({
          current_graph_id: action?.payload.sceneSetGraphId,
          current_product_id: productId as number,
          current_program_id: programId as number,
          current_scene_set_id: sceneSetId,
          destination_scene_set_id: action?.payload.destinationSceneSetId,
          source: JumpToSceneSetSources.Content,
        });
        jumpedSceneSet = jumpedSceneSetResult?.result;
      });
      if (jumpedSceneSet) {
        const path = getPathForParams({
          productReference: productReference as string,
          sceneSetGraphId: action?.payload.sceneSetGraphId as number,
          sceneSetId: action?.payload.destinationSceneSetId as number,
          sceneId: 0,
        });
        history.push(path, {
          pathname: history.location.pathname,
        });
      } else {
        const payloadStr = stringify(action?.payload);
        const paramsStr = stringify({
          productReference,
          sceneSetGraphId,
          sceneSetId,
          sceneId,
        });
        const errorStr = `JUMP_TO_SCENESET failed with payload ${payloadStr} from ${paramsStr}`;
        reporter.log(errorStr, Error(errorStr), { silent: true });
      }
    } catch (err) {
      // only QUIZ_FAILED_THROWABLE errors are intended for subsequent control flow
      if (err !== QUIZ_FAILED_THROWABLE) {
        throw err;
      }
    }
  }, [
    action,
    history,
    jumpToSceneSetWithAssets,
    productId,
    productReference,
    programId,
    sceneId,
    sceneSetGraphId,
    sceneSetId,
    submitResponses,
  ]);

  // --------------------------------------------------------------------------------
  //
  // Handle JUMP_TO_SCENESET_BY_ID action
  //
  // This does the following:
  // 1. Submits any responses
  // 2. Fetches SceneSet by sceneSetId
  // 3. Attempts to jump to
  //
  // NOTE Very similar to JUMP_TO_SCENESET

  const handleJumpToSceneByIdSetAction = useCallback(async (): Promise<
    void
  > => {
    try {
      await submitResponses(); // can throw QUIZ_FAILED_THROWABLE
      const jumpToId = action?.payload;
      const jumpedSceneSetResult = await jumpToSceneSetWithAssets({
        current_graph_id: sceneSetGraphId,
        current_product_id: productId as number,
        current_program_id: programId as number,
        current_scene_set_id: sceneSetId,
        destination_scene_set_id: jumpToId,
        source: JumpToSceneSetSources.Content,
      });
      const jumpedSceneSet = jumpedSceneSetResult?.result;
      if (jumpedSceneSet) {
        navigateToSceneSet({
          currentSceneSet: currentSceneSet as SceneSet,
          targetSceneSetId: jumpToId,
        });
      } else {
        exitContent({ fallback: 'home', productReference });
      }
    } catch (err) {
      // only QUIZ_FAILED_THROWABLE errors are intended for subsequent control flow
      if (err !== QUIZ_FAILED_THROWABLE) {
        throw err;
      }
    }
  }, [
    action,
    currentSceneSet,
    exitContent,
    jumpToSceneSetWithAssets,
    navigateToSceneSet,
    productId,
    productReference,
    programId,
    sceneSetGraphId,
    sceneSetId,
    submitResponses,
  ]);

  // --------------------------------------------------------------------------------
  //
  // SUBMIT action
  //
  // This does the following:
  // 1. submits data to the API in order to return the next SceneSet with correct data
  // 2. if the SceneSet is empty (strictly, "null") it sends the app back to "/home"

  const handleSubmitAction = useCallback(async (): Promise<void> => {
    try {
      await submitResponses(); // can throw QUIZ_FAILED_THROWABLE
      navigateToNextContent();
    } catch (err) {
      // can throw QUIZ_FAILED_THROWABLE so only log those that aren't that
      if (err !== QUIZ_FAILED_THROWABLE) {
        throw err;
      }
    }
  }, [submitResponses, navigateToNextContent]);

  // --------------------------------------------------------------------------------
  //
  // Handle OPEN_LINK action
  //
  // Opens a url in a new tab for web
  // Opens a url in default browser for native

  const handleOpenLink = useCallback(async () => {
    if (isOpenLinkAction(action)) {
      await openLink(action.payload);
    }
  }, [action]);
  let handler: ActionHandlerCallback | undefined;
  switch (action?.type) {
    case SceneActionTypes.NEXT:
    case SceneActionTypes.SKIP_TO_NEXT:
      handler = handleNextAction;
      break;

    case SceneActionTypes.GO_TO_HOME:
      handler = handleGoHomeAction;
      break;

    case SceneActionTypes.GO_TO:
      handler = handleGoToAction;
      break;

    case SceneActionTypes.REPEAT_CURRENT_SCENESET:
      handler = handleRepeatAction;
      break;

    case SceneActionTypes.JUMP_TO_SCENESET:
      handler = handleJumpToSceneSetAction;
      break;

    case SceneActionTypes.JUMP_TO_SCENESET_BY_ID:
      handler = handleJumpToSceneByIdSetAction;
      break;

    case SceneActionTypes.SUBMIT:
      handler = handleSubmitAction;
      break;

    case SceneActionTypes.OPEN_LINK:
      handler = handleOpenLink;
      break;

    default:
      handler = undefined;
      break;
  }
  if (typeof handler === 'function' && typeof action !== 'undefined') {
    // Start Daylight Specific Logic
    if (productReference === ProductReferences.DAYLIGHT) {
      const navigation_action_types = [
        SceneActionTypes.SUBMIT,
        SceneActionTypes.NEXT,
        SceneActionTypes.SKIP_TO_NEXT,
        SceneActionTypes.JUMP_TO_SCENESET,
        SceneActionTypes.JUMP_TO_SCENESET_BY_ID,
        SceneActionTypes.REPEAT_CURRENT_SCENESET,
      ];
      if (
        navigation_action_types.some(
          action_type => action_type === action?.type
        )
      ) {
        // On any navigation action, Daylight updates the user's last practice time in Iterable
        const og_handler: ActionHandlerCallback = handler;
        handler = async () => {
          updateUserMessagingProfile(
            {
              daylight_last_practice_time_utc: new Date().toISOString(),
            },
            programId
          );
          return og_handler();
        };
      }
    }
    // End Daylight Specific Logic

    const handleTriggerReminder = (triggerCreateReminder: string) => {
      if (triggerCreateReminder === ReminderOptions.SLEEP_DIARY) {
        redirectToNotificationsWhenReturnHome({
          dispatch,
          productReference,
          userSettings,
        });
      }
    };

    const handleTriggerServiceCalls = (serviceCalls: EffectServiceCalls) => {
      return doEffectServiceCalls(serviceCalls, serviceCaller);
    };

    handler = withEffectFactory(handler, action, {
      onCreateReminder: handleTriggerReminder,
      onTriggerServiceCalls: handleTriggerServiceCalls,
    });
  }
  return handler;
};

export default useActionHandler;
