import React, { ReactElement, useContext } from 'react';
import { Linking } from 'react-native';
import { ThemeContext } from 'styled-components/native';

import { ProductReferences } from 'common/constants/enums';
import { Text } from 'components/Text';
import { getAssetsForProduct } from 'config/getAssetsForProduct';
import { roles } from 'cross-platform/utils/roleProps';
import { DEFAULT_SUPPORT_EMAIL } from 'daylight/constants';

import { ContentView, DoubterImage, ImageView } from './styled';

export const DaylightErrorFallback = (): ReactElement => {
  const theme = useContext(ThemeContext);
  const { doubter } = getAssetsForProduct(ProductReferences.DAYLIGHT);
  return (
    <ContentView {...roles('DaylightErrorFallback')}>
      <ImageView {...roles('DoubterImage')}>
        <DoubterImage
          src={doubter}
          width={165}
          height={101}
          alt="Doubter Character"
        />
      </ImageView>

      <Text style={{ fontWeight: 'bold', fontSize: 21, paddingTop: 31 }}>
        Hmm...
      </Text>
      <Text style={{ fontSize: 18, paddingTop: 8 }}>
        We cannot load this page right now. Please force close the app and
        reopen it.
      </Text>
      <Text style={{ fontSize: 18, paddingTop: 31 }}>
        Need additional help?
      </Text>
      <Text
        {...roles('MailLink')}
        style={{
          fontSize: 18,
          color: theme.color.text.link,
          textDecorationLine: 'underline',
        }}
        onPress={() => Linking.openURL(`mailto:${DEFAULT_SUPPORT_EMAIL}`)}
      >
        {DEFAULT_SUPPORT_EMAIL}
      </Text>
    </ContentView>
  );
};
