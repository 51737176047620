import React, { ReactElement, useContext } from 'react';
import { View } from 'react-native';
import { Linking } from 'react-native';
import { ThemeContext } from 'styled-components/native';

import {
  ButtonColorSchemes,
  ButtonSizes,
  UniversalButton,
} from 'components/UniveralButtons';
import { useHistory } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';
import { CircleCheckIcon } from 'daylight/components/icons';
import { DEFAULT_SUPPORT_EMAIL } from 'daylight/constants';
import { useTransformContentStyle } from 'daylight/hooks';

import {
  ButtonContainer,
  EmailSentContainer,
  ForgotPasswordText,
} from '../ForgotPasswordScreen.styled';

export const EmailSentConfirmation = (): ReactElement => {
  const history = useHistory();
  const transformStyle = useTransformContentStyle();
  const theme = useContext(ThemeContext);
  return (
    <EmailSentContainer {...roles('email-sent-confirmation')}>
      <CircleCheckIcon size={45} />
      <ForgotPasswordText
        bold
        testID="check-email-text"
        style={{ paddingTop: transformStyle(40), fontSize: transformStyle(21) }}
      >
        Check your email
      </ForgotPasswordText>
      <ForgotPasswordText
        testID="email-sent-text"
        style={{ textAlign: 'center', fontSize: transformStyle(18) }}
      >
        An email has been sent with further instructions
      </ForgotPasswordText>
      <ButtonContainer>
        <UniversalButton
          style={{ paddingTop: transformStyle(50) }}
          {...roles('login-now-button')}
          onPress={() => history.push('/daylight/login-screen')}
          text={'Login now'}
          colorScheme={ButtonColorSchemes.Primary}
          size={ButtonSizes.Small}
        />
      </ButtonContainer>
      <View style={{ paddingTop: transformStyle(40) }}>
        <ForgotPasswordText
          testID="additional-help-text"
          style={{ textAlign: 'center', fontSize: transformStyle(18) }}
        >
          Need additional help?
        </ForgotPasswordText>
        <ForgotPasswordText
          testID="email-hello-daylight-link"
          style={{
            color: theme.color.text.link,
            textDecorationLine: 'underline',
          }}
          onPress={() => Linking.openURL(`mailto:${DEFAULT_SUPPORT_EMAIL}`)}
        >
          {DEFAULT_SUPPORT_EMAIL}
        </ForgotPasswordText>
      </View>
    </EmailSentContainer>
  );
};
