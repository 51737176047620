import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  APIErrorResponse,
  APIRequestBody,
  APIResponse,
  HttpStatusCode,
} from '../types';
import { httpClient } from './httpClient';
import { refresh_access_token } from '../UserAccountAuthentication/v1';

export type Middleware = (
  request: APIRequestBody | undefined,
  response: APIResponse
) => Promise<APIResponse>;

type Fetcher = (
  request?: APIRequestBody,
  requestConfig?: AxiosRequestConfig
) => Promise<any> | never;

export type ClientOptions = {
  url: string;
  middleware?: Middleware;
  onNoNetworkConnection?: () => void | Promise<void>;
  onSuccess?: (response: APIResponse, request?: AxiosResponse<APIResponse>['request']) => Promise<void>;
  onError?: (error: any) => void;
  onTokenRefreshFail?: (error: APIErrorResponse) => void;
  onTokenRefreshSuccess?: (
    response?: refresh_access_token.Response
  ) => void;
} & AxiosRequestConfig;

export const defaultFetcher = () => {
  throw Error('No fetcher set. You must call `APIClient.config');
};

export class APIClient {
  private static fetcher: Fetcher = defaultFetcher;

  private static tokenRefresher?: Promise<Response> = undefined;

  private static interceptorId: number | undefined = undefined;

  static fetch = async (
    request: APIRequestBody,
    requestConfig?: AxiosRequestConfig
  ): Promise<any> => {
    return await APIClient.fetcher(request, requestConfig);
  };

  static reset = () => {
    APIClient.tokenRefresher = undefined;
  };

  static configure = (options: ClientOptions) => {
    const {
      url,
      middleware,
      onSuccess,
      onError,
      onTokenRefreshSuccess,
      onTokenRefreshFail,
      onNoNetworkConnection,
      ...axiosOptions
    } = options;
    // --------------------------------------------------------------------------------
    // Set interceptor

    if (typeof APIClient.interceptorId === 'number') {
      httpClient.interceptors?.response?.eject(APIClient.interceptorId);
    }
    const id = httpClient.interceptors?.response.use(
      function successInterceptor(response: AxiosResponse<APIResponse>) {
        onSuccess?.({
          status_code: response.status,
          result: response.data.result,
        },
          response
        );
        return response;
      },
      async function errorInterceptor(
        err: AxiosError<APIErrorResponse> & {
          config: { __isRetryRequest?: boolean };
        }
      ): Promise<
        AxiosResponse<APIResponse> | AxiosError<APIErrorResponse> | void
      > {
        // if there is no internet connection
        if (err.message === 'Network Error') {
          await onNoNetworkConnection?.();
          return Promise.reject(err);
        }
        if (err.config?.__isRetryRequest) {
          return Promise.reject(err);
        }

        const statusCode = err?.response?.status || 0;
        if (
          statusCode >= HttpStatusCode.INTERNAL_SERVER_ERROR ||
          statusCode !== HttpStatusCode.UNAUTHORIZED
        ) {
          await onError?.(err);
          return Promise.reject(err);
        }

        // Set up the refresher.
        // We're using `fetch` here in order to bypass axios, otherwise we have to handle an axios error thrown
        // up by that request

        if (!APIClient.tokenRefresher) {
          APIClient.tokenRefresher = fetch(url, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              ...(axiosOptions?.headers || {}),
            } as HeadersInit ,
            body: JSON.stringify({
              service_name: 'UserAccountAuthentication',
              service_version: '1',
              service_method: 'refresh_access_token',
              args: {},
            }),
          });
        }
        try {
          // Try refreshing, if it works, then we refetch
          const refreshResult = await APIClient.tokenRefresher;
          if (typeof refreshResult === 'undefined') {
            throw Error('apiClient expected refreshResult to be defined');
          }
          // Note: Optimistically casting "any"
          const json: refresh_access_token.Response | APIErrorResponse = await refreshResult
            .clone()
            .json();

          if (typeof json?.status_code !== 'number') {
            throw Error(`apiClient expected status_code in json ${JSON.stringify(json)}`);
          }
          if (json.status_code >= HttpStatusCode.BAD_REQUEST) {
            onTokenRefreshFail?.(json as APIErrorResponse);
            APIClient.tokenRefresher = undefined;
            err.config.__isRetryRequest = true;
            return Promise.reject(err);
          }
          APIClient.tokenRefresher = undefined;
          const originalReq = err.config;
          onTokenRefreshSuccess?.(json as refresh_access_token.Response);
          return httpClient(originalReq);
        } catch (e) {
          await onError?.(e);
          APIClient.tokenRefresher = undefined;
          err.config.__isRetryRequest = true;
          return Promise.reject(err);
        }
      }
    );
    APIClient.interceptorId = id;

    // --------------------------------------------------------------------------------
    // Set fetcher

    APIClient.fetcher = async (
      request?: APIRequestBody,
      requestConfig?: AxiosRequestConfig
    ): Promise<APIResponse> => {
      const axiosResponse: AxiosResponse<APIResponse> = await httpClient({
        ...axiosOptions,
        ...requestConfig,
        url,
        data: request,
      });

      let apiResponse: APIResponse;
      if (axiosResponse.status >= 400) {
        apiResponse = {
          status_code: axiosResponse.status,
          result: null,
        };
      } else {
        apiResponse = {
          status_code: axiosResponse.status,
          result: axiosResponse.data.result,
        };
      }
      if (typeof middleware === 'function') {
        return middleware(request, apiResponse);
      }
      return apiResponse;
    };
  };

  /**
   * For testing only!!
   */
  static __getFetcher = () => APIClient.fetcher;
}
