import React, { ReactElement } from 'react';
import { TouchableHighlightProps, TouchableOpacity } from 'react-native';
import { Line, Path, Svg } from 'react-native-svg';
import styled from 'styled-components/native';

import { ProductReferences } from 'common/constants/enums';
import { PressHandler } from 'components/Button/types';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { useHistory } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';

type Pressable = {
  onClick?: PressHandler;
};

export const BackButtonContainer = styled.View``;

const Touchable = styled(TouchableOpacity)<Pressable>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 11px;
  padding-bottom: 11px;
`;

export const svgBackButtonDaylight = (
  <Svg width="16" height="16" viewBox="0 0 21 21" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292152 10.9458C-0.097384 10.5612 -0.097384 9.93981 0.292152 9.5542L9.53714 0.417333C10.0995 -0.139111 11.0144 -0.139111 11.5777 0.417333C12.14 0.973776 12.14 1.87687 11.5777 2.43332L3.66913 10.2505L11.5777 18.0657C12.14 18.6231 12.14 19.5262 11.5777 20.0827C11.0144 20.6391 10.0995 20.6391 9.53714 20.0827L0.292152 10.9458Z"
      fill="#6A717D"
    />
    <Line
      x1="1.64844"
      y1="10.2495"
      x2="19.1484"
      y2="10.2495"
      stroke="#6A717D"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </Svg>
);

export const svgBackButtonSleepio = (
  <Svg width="10" height="20" viewBox="0 0 10 20">
    <Path
      fill="none"
      fillRule="evenodd"
      stroke="#00506B"
      strokeLinecap="round"
      strokeWidth="2"
      d="M10 0L0 9 10 18"
    />
  </Svg>
);

function BackButtonRenderer() {
  const { productReference } = useSafeParams();

  if (productReference === ProductReferences.DAYLIGHT) {
    return svgBackButtonDaylight;
  }

  return svgBackButtonSleepio;
}

const BackButton = ({ ...rest }: TouchableHighlightProps): ReactElement => {
  const history = useHistory();
  const handlePress = (): void => {
    history.goBack();
  };

  return (
    <Touchable {...rest} {...roles('ModalBackButton')} onPress={handlePress}>
      <BackButtonContainer>
        <BackButtonRenderer />
      </BackButtonContainer>
    </Touchable>
  );
};

export default BackButton;
