import DeviceInfo from 'react-native-device-info';

import { BundleIds } from 'common/constants/enums';

import {
  AndroidLocalNotificationConfig,
  BHNotificationToShowConfig,
  IosLocalNotificationConfig,
  NotificationFrequency,
} from './types';

const channelId = 'daylight-daily-reminder';
const channelName = 'Daily Reminder';

export const DAYLIGHT_DAILY__NOTIFICATION_GROUP_CONFIG_ID =
  'DAYLIGHT_DAILY__NOTIFICATION_GROUP_CONFIG_ID';

export const iosConfig: IosLocalNotificationConfig = {
  DAYLIGHT_DAILY__NOTIFICATION_GROUP_CONFIG_ID: {
    channelConfig: {
      id: channelId,
    },
  },
};

/**
 * This is set up as config objects accessed by the notifGroupConfigKey
 *
 * - Intentionally not set up as an array of configs in order to simplify access
 */
export const androidConfig: AndroidLocalNotificationConfig = {
  DAYLIGHT_DAILY__NOTIFICATION_GROUP_CONFIG_ID: {
    channelConfig: {
      id: channelId,
      name: channelName,
      vibration: true,
      vibrationPattern: [300, 500],
    },
    notificationConfig: {
      channelId,
      /**
       * KNOWN NOTIFEE ERROR WITH THE `ic_launcher` method
       * @see https://notifee.app/react-native/docs/android/appearance
       * Android resources (checks mipmap first, then drawable -
       *  Note there appears to be an Android limitation where if the mipmap folder contains any xml files,
       *  the icon will not load).
       */
      smallIcon: 'ic_notif_small_icon',
      color: '#FF754F',
      pressAction: {
        id: 'default',
      },
    },
  },
};

/**
 * This is set up as a collection of configs in order
 *   to facilitate many configs (maybe many for the same notifGroupConfigKey)
 */
export const notificationsToShow: BHNotificationToShowConfig[] = [
  {
    // --- VALUES __NOT__ AVAILBLE IN NOTIFICATION INFORMATION ---
    bhNotificationGroupConfig: {
      id: DAYLIGHT_DAILY__NOTIFICATION_GROUP_CONFIG_ID,
      frequency: NotificationFrequency.DAILY,
      /**
       * FOR DEV TESTING: CONSIDER IMPLEMENTING THE BELOW CODE INSTEAD
       *
       * The code will enable a notification every 10s,
       *   which can be really helpful for quick debug cycles
       *
       * IF DAYLIGHT DEV, DO EVERY 10s, OTHERWISE, DEFAULT
       * ```javascript
       * frequency: (
       *   DeviceInfo.getBundleId() === BundleIds.DAYLIGHT_DEV
       *     ? NotificationFrequency.FOR_TESTING_ONLY__EVERY_10_SECONDS
       *     : NotificationFrequency.DAILY
       * ),
       * ```
       */
      count: 30,
      defaults: {
        timeOfDayHours24: 9,
        timeOfDayMinutes: 0,
      },
      fnForCreatingIndividualIds: (i: number): string =>
        `${DAYLIGHT_DAILY__NOTIFICATION_GROUP_CONFIG_ID}-${i}`,
    },
    // --- VALUES AVAILBLE IN NOTIFICATION INFORMATION ---
    title: 'Time to practice!',
    body: '5-10 minutes of daily practice can help you feel better.',
    data: {
      notificationGroupConfigFrequency:
        DeviceInfo.getBundleId() === BundleIds.DAYLIGHT_DEV
          ? // Now, only in daylight DEV builds, we can test more quickly
            NotificationFrequency.FOR_TESTING_ONLY__EVERY_10_SECONDS
          : NotificationFrequency.DAILY,
      notificationGroupConfigId: DAYLIGHT_DAILY__NOTIFICATION_GROUP_CONFIG_ID,
    },
  },
];
