import { QueryObserverResult, useQuery } from 'react-query';

import { APIErrorResponse } from '@bighealth/api';
import { jump_to_specific_scene_set } from '@bighealth/api/SceneSetGraph/v1';
import { JumpToSceneSetSource } from '@bighealth/types';

import { useSafeParams } from 'components/Routes/useSafeParams';
import { getWhichDeveloperSceneSet } from 'developer/helpers';
import { jumpToSceneSetResponseHandler } from 'lib/api/helpers/jumpToSceneSetResponseHandler';

import { FEG_210_stub_check } from '../utils';

import { useQueryProduct } from './useQueryProduct';
import { useQueryProgram } from './useQueryProgram';

type Options = {
  enabled?: boolean;
  source?: JumpToSceneSetSource;
};

export type JumpResponseType = QueryObserverResult<
  jump_to_specific_scene_set.Response,
  APIErrorResponse
>;

type Caller = typeof jump_to_specific_scene_set;

/**
 * Calls the jump_to_specific_scene_set endpoint and redirects to a Scene Set if the Scene Set is resumable. Otherwise, it'll jump to the Home screen
 * @param {number | undefined} destinationId Destination Scene Set Id
 * @param {Options} options Scene Set Response
 * @returns {JumpResponseType} Jump To Scene Set Response
 */
export const useQueryJumpToSceneSet = (
  destinationId: number | undefined,
  options?: Options
): JumpResponseType => {
  const { sceneSetGraphId, sceneSetId, productReference } = useSafeParams();
  const productId = useQueryProduct().data?.result.id;
  const programId = useQueryProgram().data?.result.id;
  const developerSceneSet = getWhichDeveloperSceneSet(
    sceneSetGraphId,
    destinationId
  );

  const result = useQuery<
    jump_to_specific_scene_set.Response,
    APIErrorResponse
  >({
    queryKey: ['SceneSet', destinationId || 'noId'],
    queryFn: async () => {
      if (developerSceneSet) {
        const caller = () => ({
          status_code: 200,
          result: {
            id: destinationId as number,
            reference: `${destinationId}`,
            scene_set_json: developerSceneSet,
          },
        });
        return (caller as unknown) as ReturnType<Caller>;
      }

      if (!sceneSetGraphId || !destinationId) {
        throw new Error('sceneSetGraphId or destinationId is missing.');
      }

      const response = await jump_to_specific_scene_set(
        // cc:FEG_210_stub#end
        FEG_210_stub_check({
          current_graph_id: sceneSetGraphId,
          current_product_id: productId as number,
          current_program_id: programId as number,
          current_scene_set_id: sceneSetId,
          destination_scene_set_id: destinationId,
          source: options?.source,
        })
      );
      return jumpToSceneSetResponseHandler(
        sceneSetGraphId,
        productReference,
        destinationId,
        response
      );
    },
    enabled:
      options?.enabled !== false &&
      typeof productId === 'number' &&
      typeof programId === 'number',
    cacheTime: 0,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: false,
  });
  return result;
};
