export const ActionLock = {
  isLocked: false,
  lock() {
    this.isLocked = true;
  },
  unlock() {
    this.isLocked = false;
  },
  check() {
    return this.isLocked;
  },
};
