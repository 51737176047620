import styled from 'styled-components/native';

import { Text as TextUnstyled } from 'components/sceneset-components/Text';

const DOMAIN = 'GenericFooter';

export const Text = styled(TextUnstyled)`
  color: ${props => props.theme.color.overlay.primary};
  font-size: 16px;
  font-weight: normal;
`;
Text.displayName = `${DOMAIN}.Text`;

export const TextWithLinePadding = styled(Text)`
  color: ${props => props.theme.color.overlay.primary};
  margin-bottom: 8px;
`;
TextWithLinePadding.displayName = `${DOMAIN}.TextWithLinePadding`;

export const CollapsedNavText = styled(TextUnstyled)`
  color: ${props => props.theme.color.text.primaryInverse};
  font-size: 16px;
  font-weight: normal;
`;
CollapsedNavText.displayName = `${DOMAIN}.CollapsedNavText`;
