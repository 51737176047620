import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { get_settings } from '@bighealth/api/UserSleepioMetadata/v1';

import { UserAccountType } from 'common/constants/enums';
import { LoadingBlankScreen } from 'components/LoadingBlankScreen';
import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import {
  LOCAL_NOTIFICATION_ENABLED,
  PLATGEN_FF_MFA_ENABLED,
} from 'config/envVars';
import { PLATGEN_NHS_LINK } from 'config/envVars';
import { roles } from 'cross-platform/utils/roleProps';
import { toJavasScriptDate } from 'lib/api/middleware/response/toJavaScriptDateWithRequest/utils/toJavaScriptDate';
import {
  queryClientSubscribe,
  queryClientUnsubscribe,
  useQueryProduct,
  useQuerySettings,
} from 'lib/api/reactQueryHelpers';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';
import * as reporter from 'lib/reporter';
import { FitbitUserMessage, useFitbitApi } from 'sleepio/components/Fitbit';
import { getUserInfo } from 'state/user/selectors';

import { LocalNotificationsSettings } from './components/LocalNotificationsSettings';
import { SettingsSwitch } from './components/SettingsSwitch';
import MFAModal from './MFA/MFAModal';
import {
  Container,
  Content,
  ContentColumn,
  EntryText,
  PageTitle,
  ScrollColumn,
  SettingsEntry,
} from './components';
import { EntryTextRowContainer, Padding, TextContainer } from './styled';

const AccountStatus = (): ReactElement | null => {
  const { isLoading, data } = useQuerySettings();
  if (isLoading) {
    return null;
  }
  if (!data) {
    return null;
  }
  return (
    <SettingsEntry
      heading={'Account Status'}
      subheading={`Full course access expires on ${format(
        toJavasScriptDate(data.result.account_expiry_utc),
        'd MMMM yyyy'
      )}.`}
    >
      <EntryTextRowContainer>
        <EntryText text={'Questions about your access? Ask us at'} />
        <Padding />
        <EntryText
          text={'hello@sleepio.com'}
          titleEmailAnchor={'Question about access'}
        />
      </EntryTextRowContainer>
    </SettingsEntry>
  );
};

const AccountDetails = (): ReactElement | null => {
  const { isLoading, data } = useQuerySettings();
  if (isLoading || !data) {
    return null;
  }
  return (
    <SettingsEntry
      heading={'Your Email'}
      subheading={data.result.email_address}
    ></SettingsEntry>
  );
};

const EmailSettings = (): ReactElement | null => {
  const { data } = useQuerySettings();
  const styles = useGetDynamicContentStyles();
  const isUserSubscribedViaApi = data?.result?.subscribed_to_emails || false;
  const [isUserSubscribed, setIsUserSubscribed] = useState(false);
  const productResponse = useQueryProduct();

  useEffect(() => {
    setIsUserSubscribed(isUserSubscribedViaApi);
  }, [isUserSubscribedViaApi]);

  const subscribe = async () => {
    try {
      // We could do this with setQueryCache but this is terser
      setIsUserSubscribed(true);
      await queryClientSubscribe({
        product_id: productResponse.data?.result.id as number,
      });
    } catch (e) {
      setIsUserSubscribed(isUserSubscribedViaApi);
      reporter.log('Could not call the subscription endpoint', e as Error);
    }
  };

  const unsubscribe = async () => {
    try {
      setIsUserSubscribed(false);
      await queryClientUnsubscribe({
        product_id: productResponse.data?.result.id as number,
      });
    } catch (e) {
      setIsUserSubscribed(isUserSubscribedViaApi);
      reporter.log('Could not call the unsubscription endpoint', e as Error);
    }
  };

  const toggleSwitch = async (): Promise<void> => {
    if (!isUserSubscribed) {
      await subscribe();
    } else {
      await unsubscribe();
    }
  };

  return (
    <SettingsEntry heading={'Receive Sleepio Emails'}>
      <EntryTextRowContainer>
        <TextContainer>
          <EntryText
            accessibilityRole="text"
            text={'Get updates on sessions, sleep diaries, and the community'}
          />
        </TextContainer>
        <SettingsSwitch
          accessibilityLabel="Get updates on sessions, sleep diaries, and the community"
          activeThumbColor="#1a80a2"
          trackColor={{ false: 'rgba(34, 31, 31, 0.26)', true: '#ddefff' }}
          thumbColor={isUserSubscribed ? '#1a80a2' : '#F1F1F1'}
          ios_backgroundColor={
            isUserSubscribed ? '#1a80a2' : 'rgba(34, 31, 31, 0.26)'
          }
          onValueChange={toggleSwitch}
          value={isUserSubscribed}
          style={{
            width: styles.settingsSwitchWidth,
            height: styles.settingsSwitchHeight,
          }}
          {...roles('EmailSubscriptionSwitch')}
        />
      </EntryTextRowContainer>
    </SettingsEntry>
  );
};

export const FitBitSettings = (): ReactElement => {
  const { connect, disconnect, isFitbitConnected, messageInfo } = useFitbitApi(
    'settings'
  );
  const styles = useGetDynamicContentStyles();

  const toggleSwitch = () => {
    if (isFitbitConnected) {
      disconnect();
    } else {
      connect();
    }
  };

  return (
    <SettingsEntry heading={'Sync your Fitbit'}>
      <EntryTextRowContainer>
        <TextContainer>
          <EntryText text={'Import your sleep data into your Sleep Diary'} />
          <FitbitUserMessage messageInfo={messageInfo} />
        </TextContainer>
        <SettingsSwitch
          aria-label="Import your sleep data into your Sleep Diary"
          accessibilityLabel="Import your sleep data into your Sleep Diary"
          activeThumbColor="#1a80a2"
          trackColor={{ false: 'rgba(34, 31, 31, 0.26)', true: '#ddefff' }}
          thumbColor={isFitbitConnected ? '#1a80a2' : '#F1F1F1'}
          ios_backgroundColor={
            isFitbitConnected ? '#1a80a2' : 'rgba(34, 31, 31, 0.26)'
          }
          onValueChange={toggleSwitch}
          value={isFitbitConnected}
          style={{
            width: styles.settingsSwitchWidth,
            height: styles.settingsSwitchHeight,
          }}
          {...roles('FitBitConnectionSwitch')}
        />
      </EntryTextRowContainer>
    </SettingsEntry>
  );
};

// Currenty behind a feature flag
// DEV_PLATGEN_FF_MFA_ENABLED
const MFASettings = (): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const queryClient = getQueryClient();
  const { data: MFAData } = useQuerySettings();
  const [isVisible, setIsVisible] = useState(false);

  // If MFA is Enable / Disabled, then invalidate the query response
  // and get the latest changes from BE.  BE = Source of Truth.
  const updateMFASettings = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [get_settings.queryKey],
      exact: true,
    });
  }, [queryClient]);

  const toggleMFASwitch = () => {
    setIsVisible(true);
  };

  return (
    <SettingsEntry heading={'Multi-factor authentication'}>
      <EntryTextRowContainer>
        <TextContainer>
          <EntryText
            text={
              'Add extra security to your account when you log in with multi-factor authentication.'
            }
          />
        </TextContainer>
        <SettingsSwitch
          accessibilityLabel="Add extra security to your account when you log in with multi-factor authentication."
          activeThumbColor="#1a80a2"
          trackColor={{ false: 'rgba(34, 31, 31, 0.26)', true: '#ddefff' }}
          thumbColor={MFAData?.result?.mfa_enabled ? '#1a80a2' : '#F1F1F1'}
          ios_backgroundColor={
            MFAData?.result?.mfa_enabled ? '#1a80a2' : 'rgba(34, 31, 31, 0.26)'
          }
          onValueChange={() => {
            toggleMFASwitch();
          }}
          value={MFAData?.result?.mfa_enabled}
          style={{
            width: styles.settingsSwitchWidth,
            height: styles.settingsSwitchHeight,
          }}
          {...roles('MFAEnabledSwitch')}
        />
      </EntryTextRowContainer>
      {isVisible && (
        <MFAModal
          visible={isVisible}
          isMFAEnabled={MFAData?.result?.mfa_enabled}
          onRequestClose={() => setIsVisible(false)}
          updateMFASettings={updateMFASettings}
        />
      )}
    </SettingsEntry>
  );
};

const NHSSettings = (): ReactElement | null => {
  return (
    <SettingsEntry heading={'Manage NHS login account'}>
      <EntryTextRowContainer>
        <EntryText text={'Manage your NHS login account'} />
        <Padding />
        <EntryText text={'here'} titleLinkAnchor={PLATGEN_NHS_LINK} />
      </EntryTextRowContainer>
    </SettingsEntry>
  );
};

const SettingsScreen = (): ReactElement | null => {
  useUpdateTitle('Account Settings');
  const { isLoading, data } = useQuerySettings();
  const userAccountType = useSelector(getUserInfo)?.user_identity_provider;

  if (isLoading) {
    // the global throbber will be rendered
    return <LoadingBlankScreen />;
  }

  if (typeof data?.result === 'undefined') {
    return null;
  }
  return (
    <Container>
      <PageTitle>{'Account Settings'}</PageTitle>
      <ContentColumn>
        <ScrollColumn contentContainerStyle={{ flex: 1 }}>
          <Content>
            <AccountStatus />
            <AccountDetails />
            <EmailSettings />
            <FitBitSettings />
            {PLATGEN_FF_MFA_ENABLED &&
            userAccountType === UserAccountType.PLATGEN ? (
              <MFASettings />
            ) : null}
            {LOCAL_NOTIFICATION_ENABLED ? <LocalNotificationsSettings /> : null}
            {PLATGEN_NHS_LINK &&
            userAccountType === UserAccountType.EXTERNAL ? (
              <NHSSettings />
            ) : null}
          </Content>
        </ScrollColumn>
      </ContentColumn>
    </Container>
  );
};

export default SettingsScreen;
