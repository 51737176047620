import React, { ReactElement, useContext, useRef } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { ResponseType } from '@bighealth/types';

import { useInputFocusContext } from 'components/forms/ResponseOptions/providers/InputFocusProvider';
import {
  GenericButtonText,
  GenericButtonTextContainer,
  GenericInputButton,
} from 'components/generic-question/GenericInputButton';
import { Input as InputWeb } from 'components/primitives/web-only';
import { useGetDynamicInputStyles } from 'components/ResponsiveLayout';
import { ErrorText } from 'components/Text/ErrorText';
import { join, roles } from 'cross-platform/utils/roleProps';
import {
  getStylesForInputStates,
  InputStates,
} from 'lib/styles/inputStateStyles';

import { getHTMLTextInputPropsFromInputType } from './helpers';
import { useFocusStateHandler } from './hooks';
import { GENERIC_INPUT_PLACEHOLDER, RawGenericInputProps } from './types';

const InputStatic = styled(InputWeb)<InputStates>`
  appearance: none;
  background-color: ${getStylesForInputStates('backgroundColor')};
  border: ${getStylesForInputStates('border')};
  box-sizing: border-box;
  color: ${getStylesForInputStates('color')};
  display: flex;
  outline: 0;
  padding-bottom: 0;
  width: 100%;
  &:focus {
    color: ${getStylesForInputStates('color')({ isActive: true })};
    background-color: ${getStylesForInputStates('backgroundColor')({
      isActive: true,
    })};
    border: ${getStylesForInputStates('border')({ isActive: true })};
  }
  &:hover {
    color: ${getStylesForInputStates('color')({ isHovering: true })};
    background-color: ${getStylesForInputStates('backgroundColor')({
      isHovering: true,
    })};
    border: ${getStylesForInputStates('border')({ isHovering: true })};
  }
  &::placeholder {
    color: ${getStylesForInputStates('color')({})};
    font-style: ${getStylesForInputStates('fontStyle')({})};
  }
`;

export const GenericInput = ({
  dataPath,
  isSelected = true, // Defaults to "true" in order to act like the $input type value
  onClick,
  onValueChange,
  type = ResponseType.TEXT,
  placeholderText,
  value,
  error,
  ...rest
}: RawGenericInputProps): ReactElement => {
  const theme = useContext(ThemeContext);
  const { onFocus, onBlur } = useInputFocusContext();
  const inputNode = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useFocusStateHandler(inputNode, !value);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const currentValue = e.currentTarget.value;
    if (typeof currentValue === 'string') {
      const cleanValue = currentValue.trim();
      if (cleanValue.length === 0) {
        return onValueChange(cleanValue);
      }
    }

    onValueChange(currentValue);
  };

  const handleButtonClick = (): void => {
    setIsEditing(true);
  };

  const handleBlur = (): void => {
    if (typeof value === 'string') {
      const trimValue = `${value || ''}`?.trim();
      if (!(trimValue === value)) {
        onValueChange(trimValue || '');
      }
    }

    if (value) {
      setIsEditing(false);
    }

    onBlur();
  };

  const handleFocus = (): void => {
    onFocus();
  };

  const {
    height,
    borderRadius,
    paddingLeft,
    paddingRight,
    fontSize,
  } = useGetDynamicInputStyles();

  const textId = 'generic_button_text' + rest.id;

  return (
    <>
      {isEditing ? (
        <InputStatic
          {...getHTMLTextInputPropsFromInputType(type)}
          {...roles(join('GenericInput-input', dataPath))}
          value={value || ''}
          onChange={handleValueChange}
          placeholder={placeholderText ?? GENERIC_INPUT_PLACEHOLDER}
          ref={inputNode}
          onBlur={handleBlur}
          onFocus={handleFocus}
          style={{
            minHeight: height,
            borderRadius,
            paddingLeft,
            paddingRight,
            paddingTop: 0,
            paddingBottom: 0,
            fontSize,
            ...(error && { borderColor: theme.color.error }),
          }}
        />
      ) : (
        <GenericButtonTextContainer>
          <GenericInputButton
            {...roles(join('GenericInput-button', dataPath))}
            isSelected={isSelected}
            isMultiSelect={true}
            isInput={true}
            style={{
              flex: 1,
            }}
            onSelect={onClick}
            onClick={onClick}
            id={rest.id}
            questionId={rest.questionId}
          >
            {(): ReactElement => (
              <GenericButtonText
                isSelected={isSelected}
                onClick={onClick}
                id={textId}
              >
                {value}
              </GenericButtonText>
            )}
          </GenericInputButton>
          <GenericInputButton
            role="button"
            style={{
              position: 'absolute',
              right: 0,
              backgroundColor: 'transparent',
              border: 'none',
            }}
            data-testid={'GenericInput-editButton'}
            isSelected={isSelected}
            onClick={handleButtonClick}
            onSelect={handleButtonClick}
            accessibilityLabel={'Edit: ' + value}
          >
            Edit
          </GenericInputButton>
        </GenericButtonTextContainer>
      )}
      {error ? <ErrorText style={{ marginTop: 4 }}>{error}</ErrorText> : null}
    </>
  );
};
