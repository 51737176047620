import React, { useState } from 'react';

import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

import { getInteractionProps } from 'components/generic-question/InputsAsArray/helpers/utils/getInteractionProps';
import {
  useTransformContentStyle,
  useTransformContentStyleVertical,
} from 'components/ResponsiveLayout';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';

import {
  ResponseCircle,
  ResponseImage,
  ResponseText,
  ResponseWrapper,
} from './styled/styled';

type ResponseProps = RoleProps &
  get_user_session_screen_data.ResponseOptionWithImage & {
    onPress: () => void;
  };

const Response = (props: ResponseProps & RoleProps): React.ReactElement => {
  const scaleFn = useTransformContentStyle();
  const scaleValue = scaleFn(1);
  const scaleYFn = useTransformContentStyleVertical();
  const scaleYValue = scaleYFn(1);

  const [isPressing, setIsPressing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  return (
    <ResponseWrapper
      {...roles(`DiaryQuestionResponse ${props.value}`)}
      isHovering={isHovering}
      isPressing={isPressing}
      activeOpacity={1}
      {...getInteractionProps({
        value: undefined,
        onSelect: props.onPress,
        setIsPressing,
        setIsHovering,
      })}
    >
      {props.image ? (
        <ResponseImage
          alt="Diary Question Response Image"
          src={props.image.storage_url}
          {...roles('DiaryQuestionResponseImage')}
        />
      ) : props.background_color ? (
        <ResponseCircle
          radius={24}
          fill={props.background_color}
          stroke={{ color: props.background_color }}
        />
      ) : null}
      <ResponseText
        scaleValue={scaleValue}
        scaleYValue={scaleYValue}
        text={props.display_text}
      />
    </ResponseWrapper>
  );
};

export { Response };
