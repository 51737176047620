import React, { Children, ReactElement, useContext, useEffect } from 'react';
import { ScrollView, View } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components/native';

import { jump_to_specific_scene_set } from '@bighealth/api/SceneSetGraph/v1';
import { Modal as ModalType } from '@bighealth/types/src/scene-components/client';

import { ProductReferences } from 'common/constants/enums';
import BackButton from 'components/BackButton';
import { useBackHandler } from 'components/backHandler/useBackHandler';
import { FillAbsoluteCenter } from 'components/Containers';
import { Errors } from 'components/forms';
import { ResponseForm } from 'components/forms/ResponseOptions/ResponseForm';
import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import { KeyboardDimensionsContext } from 'components/ProvidersContainer/KeyboardDimensionsProviders';
import {
  ScalingContext,
  ScalingContextProvider,
  ScreenResponsiveLayoutContext,
  useGetDynamicModalStyles,
  useScaleToModal,
} from 'components/ResponsiveLayout';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import {
  SceneComponentContextProvider,
  SceneComponentContextType,
} from 'components/Scene/SceneComponentContext';
import * as contentErrors from 'components/SceneSetView/components/contentErrors';
import { roles } from 'cross-platform/utils/roleProps';
import { isEditorBooleanTrue } from 'cross-platform/utils/utils';
import { useLatestQuestion } from 'lib/question-response/useLatestQuestion';
import * as contentModalActions from 'state/content-modal/actions';
import { getControlsAreVisible } from 'state/player/selectors';

import { ModalCloseButton } from './components/ModalCloseButton';
import { ProgressIndicator } from './components/ProgressIndicator';
import { useShowModalClose } from './hooks/useShowModalClose';
import { ControlsDismisser } from './ControlsDismisser';
const ModalLayoutContainer = styled(FillAbsoluteCenter)`
  background-color: transparent;
  opacity: 1;
  flex-direction: row;
  align-content: center;
`;

export type ResponsiveArgsModalBound = {
  screenWidth: number;
  width: number;
  borderRadius: number;
  marginRight: number;
  maxWidth: number;
  minWidth: number;
  paddingTop: number;
};

export const ModalBounds = styled.View<{
  backgroundColor?: string;
  shadowSize?: number;
  responsiveArgs: ResponsiveArgsModalBound;
}>`
  background-color: ${({ backgroundColor }): string =>
    backgroundColor || 'white'};
  display: flex;
  box-shadow: 0 0 ${({ shadowSize }): number => (shadowSize || 80) / 10}px
    rgba(0, 51, 102, 0.15);
  flex-direction: column;
  overflow: hidden;
  ${({
    responsiveArgs: {
      screenWidth,
      width,
      borderRadius,
      marginRight,
      maxWidth,
      minWidth,
      paddingTop,
    },
  }: {
    responsiveArgs: ResponsiveArgsModalBound;
  }): string => `
    border-radius: ${borderRadius}px;
    margin-right: ${marginRight}px;
    padding-top: ${paddingTop}px;
    ${
      screenWidth < width
        ? `
          width: 100%;
      `
        : `
          max-width: ${maxWidth}px;
          min-width: ${minWidth}px;
          width: ${width}px;
        `
    }`}
`;

/**
 * Keep track of how many modals have been rendered
 */
const CountVisibleModals = () => {
  const dispatch = useDispatch();
  useEffect(
    function onMount() {
      dispatch(contentModalActions.addMounted());
      return function onUnmount() {
        dispatch(contentModalActions.removeMounted());
      };
    },
    [dispatch]
  );
  return null;
};

export type ModalProps = React.PropsWithChildren<
  {
    showBackButton?: 'true' | 'false' | boolean;
  } & Omit<ModalType, 'component'>
>;

const Modal = ({
  children,
  style,
  alignment,
  showBackButton,
}: ModalProps): ReactElement => {
  const theme = useContext(ThemeContext);
  const safeArea = useSafeArea();
  const showModalClose = useShowModalClose();
  const isModalVisible = style?.display !== 'none';
  const controlsAreVisible = useSelector(getControlsAreVisible);

  const { height: keyboardHeight } = useContext(KeyboardDimensionsContext);
  const { screenHeight, screenWidth } = useContext(
    ScreenResponsiveLayoutContext
  );

  const {
    borderRadius,
    marginRight,
    maxWidth,
    minWidth,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    width,
    alignModal,
    justifyWithCloseButton,
  } = useGetDynamicModalStyles(theme, style, alignment);
  const scaleToModal = useScaleToModal(theme);
  const { productReference } = useSafeParams();
  useBackHandler(productReference);
  // Calculating the width needed for Close Button to appear correctly
  // on native application, Close Button size is 40px
  const closeButtonWidth =
    showModalClose && paddingRight < 50 ? 50 : paddingRight;

  const queryClient = getQueryClient();
  const { sceneSetId } = useSafeParams<SceneSetParams>();

  const questionToQuestionSet =
    queryClient.getQueryData<jump_to_specific_scene_set.Response>([
      'SceneSet',
      sceneSetId,
    ])?.result?.question_to_question_sets_map || {};

  const questionCount = Object.keys(questionToQuestionSet).length;

  const { latestQuestionId } = useLatestQuestion();

  const arrayChildren = Children.toArray(children) as ReactElement[];
  const isQuestionType = arrayChildren.some(
    childComponent => typeof childComponent.props.questionProps !== 'undefined'
  );

  function getBackButtonTopStyle() {
    if (productReference !== ProductReferences.DAYLIGHT) {
      return scaleToModal(25);
    }

    return justifyWithCloseButton ? safeArea.top + paddingTop : safeArea.top;
  }

  const backButtonTopStyle = getBackButtonTopStyle();

  return (
    <ScalingContextProvider scalingContext={ScalingContext.Modal}>
      {isModalVisible ? <CountVisibleModals /> : null}
      <ModalLayoutContainer
        // This component fills the whole screen
        pointerEvents={'box-none'}
        style={{
          justifyContent: alignModal,
          display: style?.display || 'flex',
        }}
      >
        <ModalBounds
          // This component is the outermost element for the "white" modal. It contains the shadow and the border radii etc
          {...roles(
            `ModalCore-${controlsAreVisible ? 'background' : 'foreground'}`
          )}
          backgroundColor={style?.backgroundColor}
          shadowSize={width * 0.5}
          responsiveArgs={{
            screenWidth,
            width,
            borderRadius,
            marginRight,
            maxWidth,
            minWidth,
            paddingTop: safeArea.top,
          }}
        >
          <ScrollView
            // This is the scrollable content
            style={{
              maxHeight: screenHeight,
            }}
            contentContainerStyle={{
              flexGrow: 1,
              paddingRight: justifyWithCloseButton
                ? closeButtonWidth
                : paddingRight,
            }}
          >
            <ControlsDismisser
              role={roles(
                `ControlsDismiss-${
                  controlsAreVisible ? 'background' : 'foreground'
                }`
              )}
              isForm={isModalVisible}
              style={{
                flexGrow: 1,
                paddingBottom: paddingBottom + keyboardHeight,
                paddingLeft,
                paddingTop: paddingTop,
              }}
            >
              <contentErrors.ErrorBoundary>
                <SceneComponentContextProvider
                  sceneComponentContext={SceneComponentContextType.Modal}
                >
                  <ResponseForm
                    onSubmit={() => undefined}
                    onValidate={(): Errors => {
                      return {};
                    }}
                  >
                    {children}
                  </ResponseForm>
                </SceneComponentContextProvider>
              </contentErrors.ErrorBoundary>
            </ControlsDismisser>
          </ScrollView>
          {questionCount > 0 && latestQuestionId && (
            <View
              style={{
                position: 'absolute',
                top: safeArea.top,
                justifyContent: 'center',
                alignSelf: 'center',
                width: width,
              }}
            >
              <ProgressIndicator
                {...roles('ProgressIndicator')}
                questionToQuestionSet={questionToQuestionSet}
                latestQuestionId={latestQuestionId}
                backgroundColor={style?.backgroundColor}
                isHidden={!isQuestionType}
              />
            </View>
          )}
          {isEditorBooleanTrue(showBackButton) ? (
            <View
              style={{
                position: 'absolute',
                left: scaleToModal(20),
                top: backButtonTopStyle,
              }}
            >
              <BackButton />
            </View>
          ) : null}
          {showModalClose && isModalVisible ? (
            <View
              style={{
                right: 0,
                position: 'absolute',
                width: closeButtonWidth,
                top: justifyWithCloseButton
                  ? safeArea.top + paddingTop
                  : safeArea.top,
              }}
            >
              <ModalCloseButton
                {...roles('CloseButton')}
                style={{
                  left: 5,

                  // TODO Update the close-button background color to pull from the modal
                  // close-button styles within a scene set in staff web. Currently, it is
                  // populating from the modal styles within a scene set in staff web for
                  // Daylight but has a value of undefined for Sleepio to use the default theme styles.
                  backgroundColor:
                    productReference === ProductReferences.DAYLIGHT
                      ? style?.backgroundColor
                      : undefined,
                  alignSelf: 'flex-start',
                }}
              />
            </View>
          ) : null}
        </ModalBounds>
      </ModalLayoutContainer>
    </ScalingContextProvider>
  );
};
Modal.displayName = 'Modal';

export default Modal;
