import React, {
  ComponentProps,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { Question } from '@bighealth/types';

import { OnValidateCallback } from 'components/forms';
import { QuestionId } from 'state/question-response/actions';

import { Form } from '../Form';
import { parseDiaryDateStr } from '../Form/helpers/parseDiaryDateStr';

import { getFlowState } from './helpers/getFlowState';

type FlowState = ReturnType<typeof getFlowState>;

type Props = ComponentProps<typeof Form>;

/**
 * Provides inter-field flow logic to the form
 * - visibility
 * - warnings
 * - initial values
 *
 * @param props {ComponentProps<typeof Form>}
 */
const FlowingForm = (
  props: PropsWithChildren<Props> & {
    onClose?: () => void;
    isNewForm?: boolean;
  }
): React.ReactElement => {
  const { fieldProps, form, isNewForm } = props;
  // Optimizes performance, Avoids unnecessary re-renders and Maintains consistency of references
  const initialQuestionProps = useMemo(() => {
    const props: Record<QuestionId, Question> = {};
    for (const section of form.sections) {
      for (const group of section.group) {
        props[group.questionProps.semantic_id] = group.questionProps;
      }
    }
    return props;
  }, [form.sections]);

  const diaryDate: Date | undefined =
    typeof props.diaryDateStr !== 'undefined'
      ? parseDiaryDateStr(props.diaryDateStr)
      : undefined;

  // Check if user has started editing an input
  const [hasStartedEditing, setHasStartedEditing] = useState(false);

  const [fieldState, setFieldState] = useState<FlowState>(
    // FlowingFormProps.fieldProps is used for debugging/testing
    fieldProps || getFlowState({}, initialQuestionProps, diaryDate)
  );

  const validateHandler: OnValidateCallback = useCallback(
    items => {
      const questionProps =
        isNewForm || hasStartedEditing ? undefined : initialQuestionProps;
      if (!hasStartedEditing && Object.keys(items).length > 0) {
        setHasStartedEditing(true);
      }
      const newState = getFlowState(items, questionProps, diaryDate);
      setFieldState(newState);
      return {};
    },
    [diaryDate, initialQuestionProps, hasStartedEditing, isNewForm]
  );

  return (
    <Form {...props} fieldProps={fieldState} onValidate={validateHandler} />
  );
};

export { FlowingForm };
