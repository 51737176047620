import React, { ReactElement } from 'react';

import { LoadingBlankScreen } from 'components/LoadingBlankScreen';
import { ContentPlaceholder } from 'components/Screens/ContentScreens';
import { useQuerySessionCheck } from 'lib/api/reactQueryHelpers';

export const SessionGate = ({
  minSessionRequired,
  titleScreen,
  children,
}: {
  minSessionRequired: number;
  titleScreen: string;
  children: ReactElement;
}): ReactElement => {
  const { isFetching, data } = useQuerySessionCheck({
    sessionNumber: minSessionRequired,
  });

  if (isFetching || typeof data?.result !== 'boolean') {
    // the global throbber will be rendered
    return <LoadingBlankScreen />;
  }

  if (data?.result === true) {
    return <>{children}</>;
  }
  return (
    <ContentPlaceholder
      placeholderText={
        'Information not available yet.\nComplete your first session to get started.'
      }
      title={titleScreen}
    />
  );
};
